import React from "react"

import SEO from "../components/seo"

import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Catalog from "../components/Catalog"

const Home = ({ data: gqlData }) => {
  return (
    <>
      <SEO title="Home" />
      {/* <StaticImage
        src="../images/festa_do_livro_USP_30porcento.png"
        placeholder="blurred"
        className="mt-4 mb-8"
        alt="Todavia no Salão do Livro Político 2021"
      /> */}
      <div className="flex flex-col items-center">
        <div className="max-w-fw min-w-full flex flex-col">
          <Catalog />
        </div>
      </div>
    </>
  )
}

export const pageQuery = graphql`
  query {
    navInfo {
      data
    }
  }
`

export default Home
